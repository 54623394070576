<script lang="ts" setup>
interface Props {
  number: number
  isPercentage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  number: 0,
  isPercentage: false,
})

const newValue = computed(() => props.number)
const oldValue = ref(newValue.value)

watch(
  () => props.number,
  () => {
    setTimeout(() => {
      oldValue.value = newValue.value
    }, 1000)
  },
)
</script>
<template>
  <AnimateNumber
    :start-amount="oldValue"
    :end-amount="newValue"
    :duration="Math.abs(newValue! - oldValue!) >= 10 ? 2 : 1"
  /><span v-if="isPercentage">%</span>
</template>
